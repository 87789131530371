<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <small>
      你班上的學生和使用過你的練習的成績單會出現在這裡。
    </small>
    <hr />
    <el-form
      :inline="true"
      :model="search"
      class="demo-form-inline"
      style="margin-top:20px"
      size="small"
    >
      <el-form-item label="Student">
        <el-input
          v-model="search.user_keyword"
          placeholder="Name/Username/Email"
        ></el-input>
      </el-form-item>
      <el-form-item label="Test Name">
        <el-input
          v-model="search.test_title"
          placeholder="Test Name"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-button-item">
        <el-button type="success" @click="searchPage" class="search-button"
          >Search</el-button
        >
      </el-form-item>
    </el-form>
    <el-menu :default-active="active" class="el-menu-demo" mode="horizontal">
      <el-menu-item index="1" class="menu-item"
        ><b
          ><router-link
            :to="{
              query: {
                type: 'composePractices'
              }
            }"
            class="linkInTab"
            >{{ $t("sat.Full Tests") }}</router-link
          ></b
        ></el-menu-item
      >
      <el-menu-item index="2" class="menu-item"
        ><b
          ><router-link
            :to="{
              query: {
                type: 'singlePractice'
              }
            }"
            class="linkInTab"
          >
            {{ $t("sat.Partial Tests") }}</router-link
          ></b
        ></el-menu-item
      >
      <el-menu-item index="3" class="menu-item"
        ><b
          ><router-link
            :to="{
              query: {
                type: 'drill'
              }
            }"
            class="linkInTab"
            >{{ $t("sat.Drill Practice") }}</router-link
          ></b
        ></el-menu-item
      >
    </el-menu>
    <div class="text-center" style="margin:15px 0">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :page-count="6"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>

    <el-radio-group :value="isFinished" @input="changeTest" size="medium">
      <el-radio-button :label="null">All</el-radio-button>
      <el-radio-button :label="1">{{
        $t("courses.completed")
      }}</el-radio-button>
      <el-radio-button :label="0">{{
        $t("courses.Incomplete")
      }}</el-radio-button>
    </el-radio-group>
    <el-alert
      class="mt-4 mb-3"
      v-if="resultsTotal"
      type="success"
      :closable="false"
    >
      <span slot="title">
        Results: <b>{{ resultsTotal }}</b>
      </span>
    </el-alert>
    <TestResultsTable
      :type="type"
      :results="results"
      role="admin"
      @remove="getAllResults"
      :showRole="role"
    ></TestResultsTable>
    <div class="text-center" style="margin:15px 0">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :page-count="6"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat";
import Company from "@/apis/company";
import TestResultsTable from "@/views/satTestResultsPage/components/TestResultsTable";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: "SAT TestResults - " + this.CompanyName
    };
  },

  components: {
    TestResultsTable,
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      search: {
        user_keyword: null,
        test_title: null
      },
      active: null,
      results: null,
      resultsTotal: 0,
      pageSize: 0,
      role: "admin"
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    myTitle() {
      return this.$t("sat.Test Results");
    },
    isTeacher() {
      return this.$route.name === "TeacherViewAllResults";
    },
    isAdmin() {
      return this.$route.name === "ViewAllResults";
    },
    isFinished() {
      let page = null;
      if (parseInt(this.$route.query.is_finished) > -1) {
        page = parseInt(this.$route.query.is_finished);
      }
      return page;
    },
    page() {
      let page = 1;
      if (this.$route.query.page) {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    routeName() {
      return this.$route.name;
    },
    companyId() {
      return this.getProfile.company_id;
    },
    type() {
      if (this.$route.query.type) {
        return this.$route.query.type;
      } else {
        return "composePractices";
      }
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.query.user_keyword) {
      this.search.user_keyword = this.$route.query.user_keyword;
    }
    if (this.$route.query.test_title) {
      this.search.test_title = this.$route.query.test_title;
    }
    this.getAllResults();
    // if (this.routeName === "ViewAllResults") {
    //   this.getResults();
    // }
    // if (this.routeName === "CompanyViewAllResults") {
    //   this.getCompanyResults();
    //   this.role = "company";
    // }
    // if (this.routeName === "TeacherViewAllResults") {
    //   this.getTeacherResults();
    // }
  },

  methods: {
    async getAllResults() {
      if (this.routeName === "ViewAllResults") {
        await this.getResults();
      }
      if (this.routeName === "CompanyViewAllResults") {
        await this.getCompanyResults();
        this.role = "company";
      }
      if (this.routeName === "TeacherViewAllResults") {
        await this.getTeacherResults();
      }
    },
    async getResults() {
      let results = null;
      if (this.type === "composePractices") {
        results = await SAT.getAdminComposePracticeUserExam({
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          is_finished: this.isFinished
        });
        this.active = "1";
      } else if (this.type === "singlePractice") {
        results = await SAT.getAdminPracticeUserExam({
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          practice_type: null,
          is_finished: this.isFinished
        });
        this.active = "2";
      } else if (this.type === "drill") {
        results = await SAT.getAdminPracticeUserExam({
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          practice_type: "drill",
          is_finished: this.isFinished
        });
        this.active = "3";
      }
      this.results = results.user_exams.data;
      this.pageSize = results.user_exams.per_page;
      this.resultsTotal = results.user_exams.total;
    },
    async getTeacherResults() {
      let results = null;
      if (this.type === "composePractices") {
        results = await SAT.getTeacherUserExam({
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          categories: [9, 10, 15, 16],
          is_finished: this.isFinished
        });
        this.active = "1";
      } else if (this.type === "singlePractice") {
        results = await SAT.getTeacherUserExam({
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          categories: [1, 2, 3, 4, 13, 14],
          is_finished: this.isFinished
        });
        this.active = "2";
      } else if (this.type === "drill") {
        results = await SAT.getTeacherUserExam({
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          categories: [5, 6, 7, 8, 11, 12],
          is_finished: this.isFinished
        });
        this.active = "3";
      }
      this.results = results.user_exams.data;
      this.pageSize = results.user_exams.per_page;
      this.resultsTotal = results.user_exams.total;
    },
    async getCompanyResults() {
      let results = null;
      if (this.type === "composePractices") {
        results = await SAT.getCompanyPracticeUserExam({
          test_type: "compose_practice",
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          is_finished: this.isFinished
        });
        this.active = "1";
      } else if (this.type === "singlePractice") {
        results = await SAT.getCompanyPracticeUserExam({
          test_type: "practice",
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          practice_type: null,
          is_finished: this.isFinished
        });
        this.active = "2";
      } else if (this.type === "drill") {
        results = await SAT.getCompanyPracticeUserExam({
          test_type: "practice",
          page: this.page,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title,
          practice_type: "drill",
          is_finished: this.isFinished
        });
        this.active = "3";
      }
      this.results = results.user_exams.data;
      this.pageSize = results.user_exams.per_page;
      this.resultsTotal = results.user_exams.total;
    },
    changeTest(val) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: 1, is_finished: val }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    searchPage() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title
        }
      });
    }
  }
};
</script>

<style scoped>
.box .container {
  padding-top: 0;
}
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
.linkInTab {
  color: inherit !important;
  display: block;
}

.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab:hover {
  text-decoration: none;
}
.menu-item {
  height: 40px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .box .container {
    padding: 0 15px 20px 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sat_left {
    margin: 0;
    margin-top: 10px;
  }
  .sat_left > p {
    font-weight: 700;
    font-size: 24px;
  }
}
</style>
